import { ref } from '@vue/composition-api';
import useBookingFeeType from './useBookingFeeType';
import useUtils from '@/composables/useUtils';

const useBookingFeeTypeList = () => {
  const { getBookingFeeTypeList } = useBookingFeeType();
  const {formatCurrency} = useUtils();

  const refBookingFeeTypeTable = ref(null);
  const tableColumns = [
    { key: 'name' },
    { key: 'feeAmount', formatter: (val) => formatCurrency(val) },
    { key: 'addByDefault' },
    { key: 'isAdminFee' },
    { key: 'chargeToCompany' },
    { key: 'actions' },
  ];

  const getBookingFeeList = (_, callback) => {
    getBookingFeeTypeList().then((fees) => {
      callback(fees);
    });
  };

  return {
    // Reactive
    refBookingFeeTypeTable,
    tableColumns,

    // Method
    getBookingFeeList,
  };
};

export default useBookingFeeTypeList;
