var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-booking-fee-type-sidebar","visible":_vm.isActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.bookingFeeTypeData.id ? 'Update' : 'Add New')+" Booking Fee Type ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":function($event){_vm.resetForm();
          hide();}}})],1),_c('validation-observer',{ref:"newBookingFeeTypeForm"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.validateForm()}}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"name":"name"},model:{value:(_vm.bookingFeeTypeData.name),callback:function ($$v) {_vm.$set(_vm.bookingFeeTypeData, "name", $$v)},expression:"bookingFeeTypeData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Fee Amount","label-for":"fee-amount"}},[_c('validation-provider',{attrs:{"name":"Fee Amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"fee-amount","state":errors.length > 0 ? false : null,"name":"fee-amount"},model:{value:(_vm.bookingFeeTypeData.feeAmount),callback:function ($$v) {_vm.$set(_vm.bookingFeeTypeData, "feeAmount", $$v)},expression:"bookingFeeTypeData.feeAmount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('validation-provider',{attrs:{"name":"Add By Default","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Add By Default","label-for":"add-by-default"}},[_c('v-select',{attrs:{"options":[
                { label: 'Yes', value: true },
                { label: 'No', value: false } ],"label":"label","reduce":function (options) { return options.value; },"input-id":"add-by-default"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
              var label = ref.label;
              var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:value ? 'bg-success' : 'bg-secondary',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}},{key:"selected-option",fn:function(ref){
              var label = ref.label;
              var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:value ? 'bg-success' : 'bg-secondary',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}}],null,true),model:{value:(_vm.bookingFeeTypeData.addByDefault),callback:function ($$v) {_vm.$set(_vm.bookingFeeTypeData, "addByDefault", $$v)},expression:"bookingFeeTypeData.addByDefault"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Is Admin Fee","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Is Admin Fee","label-for":"is-admin-fee"}},[_c('v-select',{attrs:{"options":[
                { label: 'Yes', value: true },
                { label: 'No', value: false } ],"label":"label","reduce":function (options) { return options.value; },"input-id":"is-admin-fee"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
              var label = ref.label;
              var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:value ? 'bg-success' : 'bg-secondary',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}},{key:"selected-option",fn:function(ref){
              var label = ref.label;
              var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:value ? 'bg-success' : 'bg-secondary',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}}],null,true),model:{value:(_vm.bookingFeeTypeData.isAdminFee),callback:function ($$v) {_vm.$set(_vm.bookingFeeTypeData, "isAdminFee", $$v)},expression:"bookingFeeTypeData.isAdminFee"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Charge Fee To Company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Charge Fee To Company","label-for":"charge-fee-to-company"}},[_c('v-select',{attrs:{"options":[
                { label: 'Yes', value: true },
                { label: 'No', value: false } ],"label":"label","reduce":function (options) { return options.value; },"input-id":"charge-fee-to-company"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
              var label = ref.label;
              var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:value ? 'bg-success' : 'bg-secondary',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}},{key:"selected-option",fn:function(ref){
              var label = ref.label;
              var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:value ? 'bg-success' : 'bg-secondary',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}}],null,true),model:{value:(_vm.bookingFeeTypeData.chargeToCompany),callback:function ($$v) {_vm.$set(_vm.bookingFeeTypeData, "chargeToCompany", $$v)},expression:"bookingFeeTypeData.chargeToCompany"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","type":"submit","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('b-spinner',{attrs:{"small":"","variant":"light"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.bookingFeeTypeData.id ? 'Update' : 'Add')+" ")],1),_c('b-button',{attrs:{"type":"button","variant":"outline-secondary"},on:{"click":function($event){_vm.resetForm();
              hide();}}},[_vm._v(" Cancel ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }