<template>
  <b-modal
    ref="refModal"
    id="edit-voucher-num-modal"
    title="Edit Voucher Num"
    ok-title="Update"
    no-close-on-backdrop
    cancel-variant="outline-secondary"
    @ok="validateForm"
  >
    <validation-observer ref="updateVoucherNumForm">
      <b-form
        id="update-voucher-num"
        class="p-2"
      >
        <validation-provider
          #default="{ errors }"
          name="Old Voucher Num"
          rules="required"
        >
          <b-form-group
            label="Old Voucher Num"
            label-for="old-voucher-num"
          >
            <b-form-input
              id="old-voucher-num"
              v-model="voucherData.oldVoucherNum"
              placeholder="Voucher Number"
              :state="errors.length > 0 ? false : null"
              name="old-voucher-num"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          name="New Voucher Num"
          rules="required"
        >
          <b-form-group
            label="New Voucher Num"
            label-for="new-voucher-num"
          >
            <b-form-input
              id="new-voucher-num"
              v-model="voucherData.newVoucherNum"
              placeholder="Voucher Number"
              :state="errors.length > 0 ? false : null"
              name="new-voucher-num"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { BButton, BForm, BFormGroup, BFormInput } from 'bootstrap-vue';
import { ref, watch } from '@vue/composition-api';
import { required } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import useBooking from '@/modules/booking/composables/useBooking';
import vSelect from 'vue-select';
import Swal from 'sweetalert2';
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  setup(_, ctx) {
    const toast = useToast();
    const { updateVoucherNum } = useBooking();
    const updateVoucherNumForm = ref(null);
    const refModal = ref(null);
    const voucherData = ref({ oldVoucherNum: '', newVoucherNum: '' });

    const validateForm = async (modalEvt) => {
      modalEvt.preventDefault();

      const valid = await updateVoucherNumForm.value.validate();
      if (!valid) return;

      const { value } = await Swal.fire({
        title: 'Are you sure?',
        text: 'If you update the voucher number, it can be used again for a new booking.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, update it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      });

      if (!value) return;

      const resp = await updateVoucherNum(voucherData.value);

      if (resp === null) return;
      toast.success({
        component: ToastificationContent,
        props: {
          title: 'Voucher numbers has been edited successfully!',
          icon: 'CheckIcon',
        },
      });

      refModal.value.hide();

      ctx.emit('form-submitted', { ...voucherData.value });
    };

    return {
      // Reactive
      updateVoucherNumForm,
      refModal,
      voucherData,

      // Method
      validateForm,

      // Validator
      required,
    };
  },
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#update-voucher-num {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
