<template>
  <div>
    <booking-fee-type-form
      v-if="isBookingFeeTypeFormActive"
      :is-active.sync="isBookingFeeTypeFormActive"
      :bookingFeeType="bookingFeeType"
      @form-submitted="formSubmitted"
    />

    <b-card no-body>
      <div class="m-2">
        <div class="d-flex align-items-center justify-content-between">
          <h4>Press the button to reset the vouchers numbers.</h4>

          <b-button
            variant="warning"
            size="md"
            @click="resetVouchers"
          >
            <span class="text-nowrap">Reset Vouchers Num.</span>
          </b-button>
        </div>
      </div>
    </b-card>

    <b-card no-body>
      <div class="m-2">
        <div class="d-flex align-items-center justify-content-between">
          <h4>Press the button to edit a vouchers numbers.</h4>

          <b-button
            variant="info"
            size="md"
            @click="openFormModal"
          >
            <span class="text-nowrap">Edit Vouchers Num.</span>
          </b-button>
        </div>
      </div>
    </b-card>

    <edit-voucher-num-modal ref="editVoucherFormRef" />

    <b-card no-body>
      <div class="m-2">
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="primary"
            @click="openForm"
          >
            <span class="text-nowrap">Add Booking Fee</span>
          </b-button>
        </div>
      </div>

      <b-table
        ref="refBookingFeeTypeTable"
        class="position-relative"
        :items="getBookingFeeList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No records found"
      >
        <template #cell(name)="data">
          <p class="font-weight-bold d-block text-nowrap">
            {{ data.item.name }}
          </p>
        </template>

        <template #cell(addByDefault)="data">
          <b-badge
            pill
            :variant="
              data.item.addByDefault ? 'light-success' : 'light-secondary'
            "
          >
            {{ data.item.addByDefault ? 'Yes' : 'No' }}
          </b-badge>
        </template>

        <template #cell(isAdminFee)="data">
          <b-badge
            pill
            :variant="
              data.item.isAdminFee ? 'light-success' : 'light-secondary'
            "
          >
            {{ data.item.isAdminFee ? 'Yes' : 'No' }}
          </b-badge>
        </template>

        <template #cell(chargeToCompany)="data">
          <b-badge
            pill
            :variant="
              data.item.chargeToCompany ? 'light-success' : 'light-secondary'
            "
          >
            {{ data.item.chargeToCompany ? 'Yes' : 'No' }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="editFeeType(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BRow,
  BTable,
} from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import BookingFeeTypeForm from '@/modules/booking-settings/components/BookingFeeTypeForm.vue';
import Swal from 'sweetalert2';
import useBookingFeeType from '@/modules/booking-settings/composables/useBookingFeeType';
import useBookingFeeTypeList from '@/modules/booking-settings/composables/useBookingFeeTypeList';
import useBooking from '@/modules/booking/composables/useBooking';
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import EditVoucherNumModal from '@/modules/booking-settings/components/EditVoucherNumModal.vue';

export default {
  setup() {
    const toast = useToast();
    const { resetVouchersNum } = useBooking();
    const { refBookingFeeTypeTable, tableColumns, getBookingFeeList } =
      useBookingFeeTypeList();

    const bookingFeeTypeEmpty = {
      addByDefault: null,
      bookingAdditionalFees: null,
      feeAmount: null,
      id: null,
      isAdminFee: null,
      chargeToCompany: null,
      name: null,
    };

    const isBookingFeeTypeFormActive = ref(false);
    const bookingFeeType = ref({ ...bookingFeeTypeEmpty });
    const editVoucherFormRef = ref(null);
    const openFormModal = () => {
      editVoucherFormRef.value.$refs.refModal.show();
    };

    const editFeeType = (bookingFeeTypeSelected) => {
      bookingFeeType.value = { ...bookingFeeTypeSelected };
      isBookingFeeTypeFormActive.value = true;
    };

    const formSubmitted = () => {
      isBookingFeeTypeFormActive.value = false;
      bookingFeeType.value = { ...bookingFeeTypeEmpty };
      refBookingFeeTypeTable.value.refresh();
    };

    const openForm = () => {
      bookingFeeType.value = { ...bookingFeeTypeEmpty };
      isBookingFeeTypeFormActive.value = true;
    };

    const resetVouchers = async () => {
      const { value } = await Swal.fire({
        title: 'Are you sure?',
        text: "If you reset the voucher numbers, they can be used again for new bookings.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, reset it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      });

      if (!value) return;

      await resetVouchersNum();

      toast.success({
        component: ToastificationContent,
        props: {
          title: 'Voucher numbers has been reseted successfully!',
          icon: 'CheckIcon',
        },
      });
    };

    return {
      // Reactive
      bookingFeeType,
      isBookingFeeTypeFormActive,
      tableColumns,
      refBookingFeeTypeTable,
      editVoucherFormRef,

      // Method
      openFormModal,
      editFeeType,
      formSubmitted,
      getBookingFeeList,
      openForm,
      resetVouchers,
    };
  },
  components: {
    BBadge,
    BButton,
    BCard,
    BCol,
    BDropdown,
    BDropdownItem,
    BRow,
    BTable,
    BookingFeeTypeForm,
    EditVoucherNumModal,
  },
};
</script>
