import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const useBookingFeeType = () => {
  const toast = useToast();

  const createBookingFeeType = async (bookingFeeType) => {
    try {
      return await store.dispatch('bookingFeeType/create', bookingFeeType);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error creating the booking fee type',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const getBookingFeeTypeList = async () => {
    try {
      return await store.dispatch('bookingFeeType/getList');
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error getting booking fee type list',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const updateBookingFeeType = async (bookingFeeType) => {
    try {
      await store.dispatch('bookingFeeType/update', bookingFeeType);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Error updating booking fee type',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  return {
    // Methods
    createBookingFeeType,
    getBookingFeeTypeList,
    updateBookingFeeType,
  };
};

export default useBookingFeeType;
