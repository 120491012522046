import { useToast } from 'vue-toastification/composition';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const useBooking = () => {
  const toast = useToast();

  const createBooking = async (booking) => {
    try {
      return await store.dispatch('booking/create', booking);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const getBooking = async (id) => {
    try {
      return await store.dispatch('booking/getBooking', id);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: 'Booking not found',
          icon: 'XCircleIcon',
        },
      });
    }
  };

  const updateBooking = async (booking) => {
    try {
      return await store.dispatch('booking/update', booking);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const resetVouchersNum = async () => {
    try {
      await store.dispatch('booking/resetVouchersNum');
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const updateVoucherNum = async (voucherData) => {
    try {
      await store.dispatch('booking/updateVoucherNum', voucherData);
      return true
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const changeBookingStatus = async (bookingId) => {
    try {
      await store.dispatch('booking/changeBookingStatus', bookingId);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const cancelBooking = async (bookingId) => {
    try {
      await store.dispatch('booking/cancelBooking', bookingId);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  const downloadBooking = async (bookingId) => {
    try {
      return await store.dispatch('booking/downloadBooking', bookingId);
    } catch (error) {
      toast.error({
        component: ToastificationContent,
        props: {
          title: error.response.data,
          icon: 'XCircleIcon',
        },
      });
      return null;
    }
  };

  return {
    // Method
    changeBookingStatus,
    createBooking,
    getBooking,
    updateBooking,
    resetVouchersNum,
    downloadBooking,
    cancelBooking,
    updateVoucherNum
  };
};

export default useBooking;
