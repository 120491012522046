<template>
  <b-sidebar
    id="add-new-booking-fee-type-sidebar"
    :visible="isActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ bookingFeeTypeData.id ? 'Update' : 'Add New' }} Booking Fee Type
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="
            resetForm();
            hide();
          "
        />
      </div>

      <!-- BODY -->
      <validation-observer ref="newBookingFeeTypeForm">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="validateForm()"
        >
          <b-form-group
            label="Name"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="bookingFeeTypeData.name"
                :state="errors.length > 0 ? false : null"
                name="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Fee Amount"
            label-for="fee-amount"
          >
            <validation-provider
              #default="{ errors }"
              name="Fee Amount"
              rules="required"
            >
              <b-form-input
                id="fee-amount"
                v-model="bookingFeeTypeData.feeAmount"
                :state="errors.length > 0 ? false : null"
                name="fee-amount"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <validation-provider
            #default="{ errors }"
            name="Add By Default"
            rules="required"
          >
            <b-form-group
              label="Add By Default"
              label-for="add-by-default"
            >
              <v-select
                v-model="bookingFeeTypeData.addByDefault"
                :options="[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]"
                label="label"
                :reduce="(options) => options.value"
                input-id="add-by-default"
              >
                <template #option="{ label, value }">
                  <div
                    class="rounded-circle d-inline-block mr-50"
                    :class="value ? 'bg-success' : 'bg-secondary'"
                    style="height: 10px; width: 10px"
                  />
                  <span> {{ label }}</span>
                </template>

                <template #selected-option="{ label, value }">
                  <div
                    class="rounded-circle d-inline-block mr-50"
                    :class="value ? 'bg-success' : 'bg-secondary'"
                    style="height: 10px; width: 10px"
                  />
                  <span> {{ label }}</span>
                </template>
              </v-select>

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="Is Admin Fee"
            rules="required"
          >
            <b-form-group
              label="Is Admin Fee"
              label-for="is-admin-fee"
            >
              <v-select
                v-model="bookingFeeTypeData.isAdminFee"
                :options="[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]"
                label="label"
                :reduce="(options) => options.value"
                input-id="is-admin-fee"
              >
                <template #option="{ label, value }">
                  <div
                    class="rounded-circle d-inline-block mr-50"
                    :class="value ? 'bg-success' : 'bg-secondary'"
                    style="height: 10px; width: 10px"
                  />
                  <span> {{ label }}</span>
                </template>

                <template #selected-option="{ label, value }">
                  <div
                    class="rounded-circle d-inline-block mr-50"
                    :class="value ? 'bg-success' : 'bg-secondary'"
                    style="height: 10px; width: 10px"
                  />
                  <span> {{ label }}</span>
                </template>
              </v-select>

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="Charge Fee To Company"
            rules="required"
          >
            <b-form-group
              label="Charge Fee To Company"
              label-for="charge-fee-to-company"
            >
              <v-select
                v-model="bookingFeeTypeData.chargeToCompany"
                :options="[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]"
                label="label"
                :reduce="(options) => options.value"
                input-id="charge-fee-to-company"
              >
                <template #option="{ label, value }">
                  <div
                    class="rounded-circle d-inline-block mr-50"
                    :class="value ? 'bg-success' : 'bg-secondary'"
                    style="height: 10px; width: 10px"
                  />
                  <span> {{ label }}</span>
                </template>

                <template #selected-option="{ label, value }">
                  <div
                    class="rounded-circle d-inline-block mr-50"
                    :class="value ? 'bg-success' : 'bg-secondary'"
                    style="height: 10px; width: 10px"
                  />
                  <span> {{ label }}</span>
                </template>
              </v-select>

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isLoading"
            >
              <b-spinner
                v-if="isLoading"
                small
                variant="light"
              />
              {{ bookingFeeTypeData.id ? 'Update' : 'Add' }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="
                resetForm();
                hide();
              "
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BSidebar,
  BSpinner
} from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import { required } from '@validations';
import { useToast } from 'vue-toastification/composition';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
import vSelect from 'vue-select';
import useBookingFeeType from '../composables/useBookingFeeType';

export default {
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    bookingFeeType: {
      type: Object,
      required: true,
    },
  },
  setup(props, ctx) {
    const toast = useToast();
    const { createBookingFeeType, updateBookingFeeType } = useBookingFeeType();

    const bookingFeeTypeData = ref(props.bookingFeeType);
    const newBookingFeeTypeForm = ref(null);
    const isLoading = ref(false)

    const resetForm = () => {
      newBookingFeeTypeForm.value.reset();
    };

    const validateForm = async () => {
      if(isLoading.value) return

      isLoading.value = true

      const valid = await newBookingFeeTypeForm.value.validate()

        if (!valid) {
          isLoading.value = false
          return;}

        try {
          bookingFeeTypeData.value.id
            ? await updateBookingFeeType(bookingFeeTypeData.value)
            : await createBookingFeeType(bookingFeeTypeData.value);

          ctx.emit('form-submitted');
        } catch (error) {
          toast.error({
            component: ToastificationContent,
            props: {
              title: 'An error occurred.',
              icon: 'XCircleIcon',
            },
          });
        }

        isLoading.value = false
    };

    return {
      // Reacitve
      bookingFeeTypeData,
      newBookingFeeTypeForm,
      isLoading,

      // Method
      resetForm,
      validateForm,

      // Validator
      required,
    };
  },
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BSidebar,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BSpinner
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-booking-fee-type-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
